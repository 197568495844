body {
  overflow: hidden;
}
.Menu_list__2NFYX {
  width: 250px; }

.Topbar_root__KEU51 {
  flex-grow: 1; }

.Topbar_grow__3jSxr {
  flex-grow: 1; }

.Topbar_menuButton__2zeWm {
  margin-left: -12px;
  margin-right: 20px; }

.Canvas_container__3MTvK {
  position: fixed;
  top: 48px;
  bottom: 0;
  left: 0;
  right: 0; }

.HelpWindow_image__2T8AV {
  max-width: 100%;
  display: block;
  margin-left: auto;
  margin-right: auto;
  margin-top: 1rem;
  margin-bottom: 1rem; }

