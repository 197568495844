.root {
  flex-grow: 1;
}

.grow {
  flex-grow: 1;
}

.menuButton {
  margin-left: -12px;
  margin-right: 20px;
}